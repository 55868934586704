/* global axios */
import ApiClient from '../ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  get(
    {
      inboxId,
      status,
      assigneeType,
      page,
      labels,
      teamId,
      conversationType,
      sortBy,
      updatedWithin,
    },
    signal
  ) {
    return axios.get(this.url, {
      params: {
        inbox_id: inboxId,
        team_id: teamId,
        status,
        assignee_type: assigneeType,
        page,
        labels,
        conversation_type: conversationType,
        sort_by: sortBy,
        updated_within: updatedWithin,
      },
      signal,
    });
  }

  filter(payload) {
    // console.log('API: Chiamata filter con payload:', payload);
    return axios
      .post(`${this.url}/filter`, payload.queryData, {
        params: { page: payload.page },
      })
      .then(response => {
        // console.log('API: Risposta filter success:', response);
        return response;
      })
      .catch(error => {
        console.error('API: Errore filter:', error);
        // Restituiamo un oggetto con struttura valida ma vuoto
        return {
          data: {
            meta: {
              mine_count: 0,
              all_count: 0,
              unassigned_count: 0,
              unread_count: 0,
              unread_count_by_inbox: {},
              inboxes: {},
            },
          },
        };
      });
  }

  createConversation(payload) {
    return axios.post(this.url, payload);
  }

  search({ q }) {
    return axios.get(`${this.url}/search`, {
      params: {
        q,
        page: 1,
      },
    });
  }

  toggleStatus({ conversationId, status, snoozedUntil = null }) {
    return axios.post(`${this.url}/${conversationId}/toggle_status`, {
      status,
      snoozed_until: snoozedUntil,
    });
  }

  togglePriority({ conversationId, priority }) {
    return axios.post(`${this.url}/${conversationId}/toggle_priority`, {
      priority,
    });
  }

  assignAgent({ conversationId, agentId }) {
    return axios.post(
      `${this.url}/${conversationId}/assignments?assignee_id=${agentId}`,
      {}
    );
  }

  assignTeam({ conversationId, teamId }) {
    const params = { team_id: teamId };
    return axios.post(`${this.url}/${conversationId}/assignments`, params);
  }

  markMessageRead({ id }) {
    return axios.post(`${this.url}/${id}/update_last_seen`);
  }

  markMessagesUnread({ id }) {
    return axios.post(`${this.url}/${id}/unread`);
  }

  toggleTyping({ conversationId, status, isPrivate }) {
    return axios.post(`${this.url}/${conversationId}/toggle_typing_status`, {
      typing_status: status,
      is_private: isPrivate,
    });
  }

  mute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/mute`);
  }

  unmute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/unmute`);
  }

  meta({ inboxId, status, assigneeType, labels, teamId, conversationType }) {
    const params = {
      ...(inboxId !== 0 && { inbox_id: inboxId }),
      status,
      assignee_type: assigneeType,
      labels,
      team_id: teamId,
      conversation_type: conversationType,
    };

    // console.log('API: Chiamata meta con params:', params);

    return axios
      .get(`${this.url}/meta`, { params })
      .then(response => {
        // console.log('API: url', this.url);
        if (!response.data?.meta) {
          console.error('API: Risposta meta non valida:', response.data);
          throw new Error('Struttura risposta non valida');
        }
        // console.log('API: Risposta meta valida:', response.data);
        return response;
      })
      .catch(error => {
        console.error('API: Errore meta:', error);
        return {
          data: {
            meta: {
              mine_count: 0,
              all_count: 0,
              unassigned_count: 0,
              unread_count: 0,
              unread_count_by_inbox: {},
              inboxes: {},
            },
          },
        };
      });
  }

  sendEmailTranscript({ conversationId, email }) {
    return axios.post(`${this.url}/${conversationId}/transcript`, { email });
  }

  updateCustomAttributes({ conversationId, customAttributes }) {
    return axios.post(`${this.url}/${conversationId}/custom_attributes`, {
      custom_attributes: customAttributes,
    });
  }

  fetchParticipants(conversationId) {
    return axios.get(`${this.url}/${conversationId}/participants`);
  }

  updateParticipants({ conversationId, userIds }) {
    return axios.patch(`${this.url}/${conversationId}/participants`, {
      user_ids: userIds,
    });
  }

  getAllAttachments(conversationId) {
    return axios.get(`${this.url}/${conversationId}/attachments`);
  }

  getLatestId() {
    return axios
      .get(`${this.url}/latest_id`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching latest conversation ID:', error);
        return {
          latest_id: 0,
          account_id: null,
          timestamp: null,
        };
      });
  }
}

export default new ConversationApi();
